@font-face {
  font-family: Ebrima;
  src: url(/assets/fonts/ebrima.ttf);
}

@font-face {
  font-family: EbrimaBold;
  src: url(/assets/fonts/ebrimabd.ttf);
}

@font-face {
  font-family: NotoSerif;
  src: url(/assets/fonts/NotoSerif-Regular.ttf);
}

@font-face {
  font-family: NotoSerifBold;
  src: url(/assets/fonts/NotoSerif-Bold.ttf);
}

* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body,
input,
textarea {
  color: #000;
  font-family: Ebrima, sans-serif;
}

strong {
  font-family: EbrimaBold, sans-serif;
}

body {
  background: #f2f2f2;
  font-weight: 300;
}

h1,
h2 {
  font-family: NotoSerifBold, sans-serif;
}

h1 {
  font-size: 2.625rem;
  margin: 0 0 25px 0;
}

h2 {
  color: #edc429;
  font-size: 3rem;
  margin: 0 0 25px 0;
  text-align: center;
}

h3 {
  color: #0a66b2;
}

a {
  color: #0a66b2;
  text-decoration: none;
}

a.button {
  background: #0a66b2;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  line-height: 60px;
  padding: 0 30px;
}

a.button--light {
  background: #fff;
  border: 1px solid #0a66b2;
  color: #0a66b2;
}

a.button--small {
  border-radius: 8px;
  line-height: 46px;
  padding: 0 20px;
}

a.button .icon {
  margin: 0 -5px 0 5px;
  vertical-align: middle;
  width: 20px;
}

.app {
  background: #fff;
  box-shadow: #ccc 0 0 8px;
  margin: 0 auto;
  max-width: 1180px;
  overflow: hidden;
  position: relative;
}

.page {
  z-index: 1;
}

.menu {
  background: #0a66b2;
  box-shadow: #07508d -2px 0 4px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(420px);
  transition: all 0.3s;
  width: 400px;
  z-index: 3;
}

.menu--open {
  transform: translateX(0);
}

.menu li {
  list-style: none;
}

.menu li a {
  border-bottom: 1px solid #1875c1;
  color: #fff;
  display: block;
  padding: 15px 20px;
}

.menu__close {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: flex-end;
  padding: 0 30px 0 0;
}

.header {
  align-items: center;
  background: #0a66b2;
  box-shadow: #07508d 0 2px 4px;
  display: flex;
  height: 100px;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.header__logo {
  margin: 0 0 0 35px;
}

.header__menu {
  flex: 1;
  margin: 0 30px 0 0;
  text-align: right;
}

.header__contact__link {
  background: #edc429;
  border-radius: 999px;
  color: #fff;
  display: inline-block;
  line-height: 50px;
  margin: 0 0 0 20px;
  padding: 0 30px;
}

.header__menu__link {
  border: 1px solid #fff;
  border-radius: 999px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  line-height: 50px;
  margin: 0 0 0 20px;
  padding: 0 20px 0 30px;
}

.header__menu__link img {
  margin: 0 0 0 15px;
  position: relative;
  vertical-align: middle;
  top: -2px;
  width: 26px;
}

.header__menu__link--clean {
  border: 0;
  padding: 0;
}

.header__menu__link--clean img {
  margin: 0 10px 0 0;
  position: relative;
  vertical-align: middle;
  top: -2px;
  width: 26px;
}

.mobile-menu {
  height: 26px;
  position: absolute;
  right: 20px;
  top: 10px;
  width: 26px;
}

.mobile-menu__trigger {
  height: 100%;
  width: 100%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.page__intro {
  background-position: center center;
  background-size: cover;
  font-size: 1.25rem;
}

.page__intro p {
  margin: 0 0 20px 0;
}

.page__intro ul {
  margin: 0 0 25px 40px;
}

.page__intro__content {
  background: rgb(38, 138, 220);
  background: radial-gradient(
    circle,
    rgba(38, 138, 220, 0.95) 0%,
    rgba(6, 85, 151, 0.95) 100%
  );
  color: #fff;
  overflow: auto;
  padding: 60px 65px;
}

.page__intro__content a {
  color: #edc429;
  font-weight: bold;
}

.page__intro__content a:hover {
  color: #fff;
}

.page__intro__content img {
  float: left;
  margin: 0 20px 0 0;
  max-width: 250px;
}

.page__content {
  padding: 50px;
}

.page__content .back {
  font-weight: bold;
  margin: 30px 0 0 0;
}

.footer {
  align-items: flex-end;
  background: #0a66b2;
  display: flex;
  justify-content: space-between;
  padding: 30px 50px;
}

.footer_nav ul {
  line-height: 2rem;
  list-style: none;
  margin: 0 0 20px 0;
}

.footer_nav ul a {
  color: #fff;
  font-size: 1.2rem;
}

.footer_nav p a {
  color: #fff;
}

.footer__company {
  color: #fff;
}

.footer__company__icon {
  background: url(/assets/images/mentoringplus-icon-white.svg) top center
    no-repeat;
  background-size: contain;
  color: #fff;
  display: flex;
  height: 200px;
  opacity: 0.3;
  padding: 100px 20px 0 20px;
}

article a {
  color: #07508d;
  font-family: EbrimaBold;
}

article h2 {
  font-weight: 300;
  margin: 20px 0 15px 0;
  padding: 0 20px 10px 0;
  text-align: left;
}

article h3 {
  font-family: NotoSerifBold;
  font-size: 2rem;
  margin: 40px 0 20px 0;
}

article p {
  line-height: 1.5rem;
  margin: 0 0 18px 0;
}

article hr {
  margin: 30px 0;
}

article table {
  margin: 0 0 30px 0;
  width: 100%;
}

article th {
  background: #0a66b2;
  color: #fff;
  padding: 10px;
  width: 50%;
}

article td {
  border: 1px solid #7dacd3;
  line-height: 1.6rem;
  padding: 15px 20px;
  vertical-align: top;
}

article td.image {
  text-align: center;
  vertical-align: middle;
  width: 200px;
}

article ol {
  margin: 0 0 18px 30px;
}

article ol li {
  list-style: inherit;
}

article ol li::before {
  content: none;
}

article ul {
  margin: 0 0 18px 30px;
}

article li {
  list-style: none;
  margin: 0 0 10px 0;
}

article li::before {
  content: "\2022";
  color: #0a66b2;
  display: inline-block;
  font-size: 1.3rem;
  font-weight: bold;
  margin-left: -1em;
  position: relative;
  top: 1px;
  width: 1em;
}

article td ul {
  margin-left: 20px;
}

article td li {
  list-style: none;
  position: relative;
}

article td li::before {
  display: none;
}

article td li::after {
  background: url(/assets/images/plus.svg) center center no-repeat;
  background-size: contain;
  content: "";
  height: 14px;
  left: -22px;
  position: absolute;
  top: 7px;
  width: 14px;
}

article .hero {
  width: 100%;
}

article .small {
  font-size: 0.8rem;
  margin-bottom: 15px;
}

.image--inline {
  float: left;
  font-size: 0.9rem;
  margin: 5px 30px 0 0;
}

.image--inline img {
  max-width: 100%;
  width: 300px;
}

.blog {
  border-radius: 10px;
  color: inherit;
  display: flex;
  margin: -20px;
  padding: 20px;
}

.blog:hover {
  background: #f2f2f2;
}

.blog .image {
  background-size: cover;
  height: 160px;
  width: 300px;
}

.blog .content {
  padding: 0 0 0 20px;
}

.blog h2 {
  font-size: 1.6rem;
  margin-bottom: 20px;
  text-align: left;
}

.change__list {
  display: flex;
  flex-wrap: wrap;
}

.change__row {
  display: flex;
}

.change__row:nth-child(2n) {
  flex-direction: row-reverse;
}

.change {
  align-items: center;
  background: #000;
  border-radius: 10px;
  color: #fff;
  display: flex;
  flex: 0 0 calc(50% - 60px);
  margin: 30px;
  padding: 25px 20px;
  position: relative;
}

.change::after {
  background: url(/assets/images/arrow.svg) center center no-repeat;
  background-size: 90%;
  content: "";
  display: block;
  height: 50px;
  position: absolute;
  width: 50px;
}

.change__icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  margin: 0 20px 0 0;
  height: 60px;
  width: 60px;
}

.change__row:nth-child(1) .change:nth-child(1) {
  background: #dc9626;
}

.change__row:nth-child(1) .change:nth-child(2) {
  background: #dc5426;
}

.change__row:nth-child(2) .change:nth-child(1) {
  background: #4326dc;
}

.change__row:nth-child(2) .change:nth-child(2) {
  background: #8d26dc;
}

.change__row:nth-child(3) .change:nth-child(1) {
  background: #c72aaa;
}

.change__row:nth-child(3) .change:nth-child(2) {
  background: #dcbf26;
}

.change__row:nth-child(4) .change:nth-child(1) {
  background: #5364a5;
}

.change__row:nth-child(4) .change:nth-child(2) {
  background: #53a5a3;
}

.change__row:nth-child(1) .change:nth-child(1) .change__icon {
  background-image: url(/assets/icons/01.png);
}

.change__row:nth-child(1) .change:nth-child(2) .change__icon {
  background-image: url(/assets/icons/02.png);
}

.change__row:nth-child(2) .change:nth-child(1) .change__icon {
  background-image: url(/assets/icons/03.png);
}

.change__row:nth-child(2) .change:nth-child(2) .change__icon {
  background-image: url(/assets/icons/04.png);
}

.change__row:nth-child(3) .change:nth-child(1) .change__icon {
  background-image: url(/assets/icons/05.png);
}

.change__row:nth-child(3) .change:nth-child(2) .change__icon {
  background-image: url(/assets/icons/06.png);
}

.change__row:nth-child(4) .change:nth-child(1) .change__icon {
  background-image: url(/assets/icons/07.png);
}

.change__row:nth-child(4) .change:nth-child(2) .change__icon {
  background-image: url(/assets/icons/08.png);
}

.change__row:nth-child(1) .change:nth-child(1)::after {
  right: -55px;
  top: calc(50% - 25px);
}

.change__row:nth-child(1) .change:nth-child(2)::after {
  bottom: -55px;
  left: calc(50% - 25px);
  transform: rotate(90deg);
}

.change__row:nth-child(2) .change:nth-child(1)::after {
  left: -55px;
  top: calc(50% - 25px);
  transform: scale(-1);
}

.change__row:nth-child(2) .change:nth-child(2)::after {
  bottom: -55px;
  left: calc(50% - 25px);
  transform: rotate(90deg) scaleY(-1);
}

.change__row:nth-child(3) .change:nth-child(1)::after {
  right: -55px;
  top: calc(50% - 25px);
}

.change__row:nth-child(3) .change:nth-child(2)::after {
  bottom: -55px;
  left: calc(50% - 25px);
  transform: rotate(90deg);
}

.change__row:nth-child(4) .change:nth-child(1)::after {
  left: -55px;
  top: calc(50% - 25px);
  transform: scale(-1);
}

.change__row:nth-child(4) .change:nth-child(2)::after {
  display: none;
}

.change__cta {
  padding: 30px;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .app {
    padding: 0;
    width: 100%;
  }

  .header {
    height: 80px;
    position: fixed;
    width: 100%;
  }

  .header__logo {
    background: url(/assets/images/mentoringplus-icon-white.svg) center center
      no-repeat;
    background-size: contain;
    flex-shrink: 0;
    height: 60px;
    margin-left: 15px;
    width: 60px;
  }

  .header__logo img {
    display: none;
  }

  .header__logo a {
    display: inline-block;
    height: 60px;
    width: 60px;
  }

  .menu {
    position: fixed;
    width: 100%;
  }

  .header__menu {
    margin-right: 15px;
  }

  .header__contact__link {
    display: none;
    line-height: 40px;
    margin-right: 15px;
    padding: 0 15px;
  }

  .header__menu__link {
    line-height: 40px;
    margin-left: 15px;
    padding: 0 15px 0 20px;
  }

  .header__menu__link img {
    width: 18px;
  }

  .header__menu__link--clean {
    padding: 0;
  }

  .page__content {
    padding: 60px 20px 20px 20px;
  }

  .page__intro__content {
    padding: 120px 20px 20px 20px;
  }

  .change__list,
  .change__row {
    display: block;
  }

  .change {
    margin: 0 0 20px 0;
  }

  .change::after {
    display: none;
  }

  .footer {
    padding: 20px;
  }

  .footer__company__icon {
    height: 150px;
  }

  article table.list td {
    display: table-row;
  }

  article table.list td.image {
    width: auto;
  }

  .image--inline {
    float: none;
    margin: 10px 0 10px 0;
  }
}
